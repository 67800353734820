import { Link } from "react-router-dom";
import ServiceSectorCouncilDashboard from "./src/pages/dashboard";
import {
  AppstoreOutlined,
  FileOutlined,
  BarsOutlined,
  FormOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Col, Menu, Row } from "antd";
import SectoreSkillCouncilDashboard from "./src/pages/dashboard";
import TrainingApplication from "./src/pages/TrainingApplication";
import { icon } from "@fortawesome/fontawesome-svg-core";
import CreateBatch from "./src/pages/createBatch";
import AssignStudent from "./src/pages/AssignStudents";
import ViewBatch from "./src/pages/ViewBatch";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    <Link to="/ssc-dashboard">Dashboard</Link>,
    "ssc-dashboard",
    <AppstoreOutlined />
  ),
  getItem(
    <Link to="/ssc-training-application">Training Applications</Link>,
    "ssc-training-application",
    <FileOutlined />
  ),
  getItem(
    <Link to="/ssc-create-batch">Batch</Link>,
    "ssc-create-batch",
    <BarsOutlined />
  ),
];

const SSCDashboard = () => {
  const mainUrl = window.location.pathname;
  const relUrl = mainUrl.slice(1);

  return (
    <Row>
      <Col span={4}>
        <h4 style={{ margin: "10px 23px 10px 23px" }}>
          Hello, {sessionStorage.getItem("sectorSkillName")}
        </h4>
        <Menu defaultSelectedKeys={`${relUrl}`} mode="inline" items={items} />
      </Col>
      <Col
        span={20}
        style={{ padding: "15px 30px", backgroundColor: "rgb(234, 238, 243)" }}
      >
        {relUrl == "ssc-dashboard" ? <SectoreSkillCouncilDashboard /> : ""}

        {relUrl == "ssc-training-application" ? <TrainingApplication /> : ""}
        {relUrl == "ssc-create-batch" ? <CreateBatch /> : ""}
        {relUrl == "ssc-assign-students" ? <AssignStudent /> : ""}
        {relUrl == "ssc-view-batch" ? <ViewBatch /> : ""}
      </Col>
    </Row>
  );
};
export default SSCDashboard;
