import React, { useEffect, useState } from "react";
import useTraining from "../../Constant/Container.js";
import { useLocation } from "react-router";
import { DataTable, PendingStatus } from "./style.js";
import { ApprovedStatus } from "../../../../cgm-side/style.js";
import { GrDocumentText } from "react-icons/gr";
import AppicationForm from "../../Modals/ApplicationForm/index.js";

function ViewBatch() {
  let location = useLocation();
  const [
    trainingCount,
    handleDistrictChange,
    applicationList,
    handleDistrictChangeForApplication,
    handleTableChange,
    pagination,
    handleDistrictChangeForBatch,
    trainingList,
    batchList,
    fetchApplicationForBatch,
    applicationListForBatch,
    paginationForBatch,
    handleTableChangeForBatch,
    handleAssignBatch,
    fetchApplicationForAssignedBatch,
    applicationListForAssignedBatches,
    paginationForAssignedBatch,
    handleTableChangeForAssignedBatch,
  ] = useTraining();
  const items = [
    {
      title: "Applicant's Name",
      dataIndex: "applicantName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Training Category",
      dataIndex: "trainingcategory",
    },
    {
      title: "Training Name",
      dataIndex: "trainingName",
    },
    {
      title: "District",
      dataIndex: "district",
    },
    {
      title: "Batch",
      dataIndex: "trainingBatchName",
    },
    {
      title: "Application Form",
      render: (data, index) => {
        return (
          <>
            <GrDocumentText
              onClick={() => handleFormClick(data)}
            ></GrDocumentText>
            {isModalOpen && (
              <AppicationForm
                isModalOpen={isModalOpen}
                handleFormOk={handleForOk}
                handleFormCancel={handleFormCancel}
                formData={formData}
              ></AppicationForm>
            )}
          </>
        );
      },
    },
    {
      title: "Approval",
      dataIndex: "trainingFinalStatus",
      render: (status) => {
        if (status == "Pending") {
          return <PendingStatus>{status}</PendingStatus>;
        } else if (status == "Approved") {
          return <ApprovedStatus>{status}</ApprovedStatus>;
        } else if (status == "Rejected") {
          return <PendingStatus>{status}</PendingStatus>;
        }
      },
    },
  ];
  const [formData, setFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleForOk = () => {
    setIsModalOpen(false);
  };
  const handleFormCancel = () => {
    setIsModalOpen(false);
  };

  const handleFormClick = (data) => {
    setIsModalOpen(true);
    setFormData(data);
  };

  useEffect(() => {
    // console.log(location.state.data.BatchtrainingName_id);
    // setBatchID(location.state.data.id);
    fetchApplicationForAssignedBatch(
      location.state.data.district,
      location.state.data.trainingName,
      location.state.data.id
    );
  }, []);
  return (
    <>
      <h3>Batch: {location.state.data.BatchName}</h3>
      <DataTable
        columns={items}
        dataSource={applicationListForAssignedBatches}
        pagination={paginationForAssignedBatch}
        onChange={handleTableChangeForAssignedBatch}
      ></DataTable>
    </>
  );
}

export default ViewBatch;
