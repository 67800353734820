import { Col, Modal, Row, Spin } from "antd";
import React from "react";
import { REACT_APP_BASE_URL } from "../../../../../libs/utils/urls";

function AppicationForm({
  isModalOpen,
  handleFormOk,
  handleFormCancel,

  formData,
}) {
  return (
    <>
      <div style={{ backgroundColor: "grey" }}>
        <Modal
          title="Training Application Form"
          open={isModalOpen}
          onOk={handleFormOk}
          onCancel={handleFormCancel}
        >
          <>
            <h3 style={{ color: "#0099ff" }}>Form Details: </h3>
            <Row>
              <Col span={12}>
                <h4>Applicant Name</h4>
                {formData?.applicantName}
              </Col>
              <Col span={12}>
                <h4>Gender</h4>
                {formData?.gender}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4>Aadhar Card Number</h4>
                {formData?.aadharCardNo}
              </Col>
              <Col span={12}>
                <h4>Address</h4>
                {formData?.address}
              </Col>
            </Row>
            <Row style={{ margin: "10px 0px" }}>
              <Col span={12}>
                <h4>Area Type</h4>
                {formData?.areaType}
              </Col>
              <Col span={12}>
                <h4>Taluka</h4>
                {formData?.taluka}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4>District</h4>
                {formData?.district}
              </Col>
              <Col span={12}>
                <h4>Aadhar linked account number</h4>
                {formData?.aadharLinkBankAccountNumber}
              </Col>
            </Row>
            <Row style={{ margin: "10px 0px" }}>
              <Col span={12}>
                <h4>Aadhar linked bank name</h4>
                {formData?.aadharLinkBankName}
              </Col>
              <Col span={12}>
                <h4>Aadhar linked IFCS Code</h4>
                {formData?.aadharLinkBankifscCode}
              </Col>
            </Row>

            <Row style={{ margin: "10px 0px" }}>
              <Col span={12}>
                <h4>Income Certificate</h4>
                {formData.incomeCertificate !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.incomeCertificate}>
                    Income Certifcate
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
              <Col span={12}>
                <h4>Ration Card</h4>
                {formData.rationCard !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.rationCard}>
                    Ration Card
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4>School/College Leaving Certificate</h4>
                {formData.schoolOrCollegeLeavingCertificate !== null ? (
                  <a
                    href={
                      REACT_APP_BASE_URL +
                      formData?.schoolOrCollegeLeavingCertificate
                    }
                  >
                    Leaving Cerficate
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
              <Col span={12}>
                <h4>Aadhar card</h4>
                {formData.aadharCard !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.aadharCard}>
                    Aadhar Card
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4>Bank passbook</h4>
                {formData.bankPassbook !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.bankPassBook}>
                    Bank passbook
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
              <Col span={12}>
                <h4>Passport photo</h4>
                {formData.passportSizePhoto !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.passportSizePhoto}>
                    Passport photo
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
            </Row>
            <Row>
            <Col span={12}>
                <h4> Caste certificate</h4>
                {formData.casteCertificate !== null ? (
                  <a href={REACT_APP_BASE_URL + formData?.casteCertificate}>
                    Caste certificate
                  </a>
                ) : (
                  <>Not uploaded</>
                )}
              </Col>
            </Row>
          </>
        </Modal>
      </div>
    </>
  );
}

export default AppicationForm;
