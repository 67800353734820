import { Card, Form } from "antd";
import styled from "styled-components";

export const MainContainer = styled.div`
  margin: 30px;
`;
export const Cards = styled(Card)`
  width: 220px;
  height: 120px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  .ant-card-body {
    padding: 15px;
  }
`;
export const CardContainer = styled.span`
  display: flex;
  justify-content: space-around;
`;
export const Count = styled.h2`
  color: #006bb3;
`;
export const FormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label {
    text-align: left;
    margin-bottom: 5px;
  }
`;