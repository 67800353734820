import React from "react";
import useTraining from "../../Constant/Container.js";
import { DataTable, PendingStatus } from "./style.js";
import { render } from "@testing-library/react";
import AppicationForm from "../../Modals/ApplicationForm/index.js";
import { GrDocumentText } from "react-icons/gr";
import { useState } from "react";
import { Form, Select } from "antd";
import data from "../../../../../data/dtdata.json";
import { FormItem } from "../../../style.js";
import { Option } from "antd/lib/mentions/index.js";
import { ApprovedStatus } from "../../../../cgm-side/style.js";


function TrainingApplication() {
  
  const [
    trainingCount,
    handleDistrictChange,
    applicationList,
    handleDistrictChangeForApplication,
    handleTableChange,
    pagination,
    handleDistrictChangeForBatch,
    trainingList,
    batchList,
    fetchApplicationForBatch,
    applicationListForBatch,
    paginationForBatch,
    handleTableChangeForBatch,
    handleAssignBatch,
    fetchApplicationForAssignedBatch,
    applicationListForAssignedBatches,
    paginationForAssignedBatch,
    handleTableChangeForAssignedBatch
  ] = useTraining();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState(null);
  const [district, setDistrict] = useState("");
  const handleForOk = () => {
    setIsModalOpen(false);
  };
  const handleFormCancel = () => {
    setIsModalOpen(false);
  };

  const handleFormClick = (data) => {
    setIsModalOpen(true);
    setFormData(data);
  };

  const items = [
    {
      title: "Applicant's Name",
      dataIndex: "applicantName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Training Category",
      dataIndex: "trainingcategory",
    },
    {
      title: "Training Name",
      dataIndex: "trainingName",
    },
    {
      title: "District",
      dataIndex: "district",
    },
    {
      title: "Batch",
      dataIndex: "trainingBatchName",
    },
    {
      title: "Application Form",
      render: (data, index) => {
        return (
          <>
            <GrDocumentText
              onClick={() => handleFormClick(data)}
            ></GrDocumentText>
            {isModalOpen && (
              <AppicationForm
                isModalOpen={isModalOpen}
                handleFormOk={handleForOk}
                handleFormCancel={handleFormCancel}
                formData={formData}
              ></AppicationForm>
            )}
          </>
        );
      },
    },
    {
      title: "Approval",
      dataIndex: "trainingFinalStatus",
      render: (status) => {
        if (status == "Pending") {
          return <PendingStatus>{status}</PendingStatus>;
        } else if (status == "Approved") {
          return <ApprovedStatus>{status}</ApprovedStatus>;
        } else if (status == "Rejected") {
          return <PendingStatus>{status}</PendingStatus>;
        }
      },
    },
  ];
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ margin: "30px 0px 0px 20px" }}>
          <h2>Applications List</h2>
        </div>
        <Form layout="vertical">
          <FormItem label="Select District">
            <Select
              showSearch
              placeholder="Select district"
              optionFilterProp="children"
              onChange={(v, k) => {
                setDistrict(v);
                // setTalukaKey(k.key);
                // setSelectVisible(false);
                // handleDistrictChange(v);
                handleDistrictChangeForApplication(v);

                console.log(v);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              //   value={district}
              style={{ width: "250px" }}
              name="district"
            >
              {data.map((dis, index) => {
                return (
                  <Option
                    key={index}
                    value={dis.district_name}
                    // onChange={(e)=>setDistrict(e)}
                  >
                    {dis.district_name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </Form>
      </div>
      <DataTable
        columns={items}
        dataSource={applicationList}
        pagination={pagination}
        onChange={handleTableChange}
      ></DataTable>
    </>
  );
}

export default TrainingApplication;
