import React from "react";
import {
  CardContainer,
  UserIcon,
  Cards,
  Count,
  DataTable,
  SSCCard,
  SSCCount,
} from "../../../../style";
import { Row, Col, Spin, Tabs, Card, Divider } from "antd";
import { CardsTable, LoadContainer } from "./style";
import useTraining from "../container";
import { UserGroup } from "../../../../../../libs/utils/sessionStorage";
import { CardTable } from "../../../../../admin-side/style";

const DistrictManagerDashboard = () => {
  const DistrictwiseLoanApplication = [
    { title: "District", dataIndex: "district" },
    { title: "Total Application", dataIndex: "DistrictWiseApplication" },
    { title: "Application Pending", dataIndex: "LoanApplicationPending" },
    { title: "DM Query", dataIndex: "LoanDmQuery" },
    { title: "DM Verified", dataIndex: "LoanDmVerified" },
    { title: "RM Reject", dataIndex: "LoanRMReject" },
    { title: "Loan Active", dataIndex: "LoanActive" },
  ];
  const SchemewiseLoanApplication = [
    {
      title: "Scheme",
      dataIndex: "LoanScheme",
    },
    {
      title: "Application Count",
      dataIndex: "TotalCount",
    },
  ];

  const trainingApplication = [
    { title: "District", dataIndex: "district" },
    { title: "Total", dataIndex: "TotalTrainingApplication" },
    { title: "Pending", dataIndex: "TotalTrainingPendingApplication" },
    { title: "Shortlisted", dataIndex: "TotalTrainingShortlistedApplication" },
    { title: "Selected", dataIndex: "TotalTrainingSelectedApplication" },
    { title: "Rejected", dataIndex: "TotalTrainingRejectedApplication" },
  ];

  const adminDistrictwise = [
    { title: "District", dataIndex: "district" },
    { title: "Total Applications", dataIndex: "DistrictWiseApplication" },
    { title: "Pending", dataIndex: "LoanApplicationPending" },
    { title: "Query", dataIndex: "LoanQuery" },
  ];

  const { dmDashboard, dashboardLoading, adminLoanDashboard, SSC_DASHBOARD } =
    useTraining();
  if (dashboardLoading == true) {
    <LoadContainer>
      <Spin tip="Loading data..." spinning={true} />
    </LoadContainer>;
  } else {
    return UserGroup == "admin" ? (
      <div>
        <h3>Loan Dashboard</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Cards>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <h4>Total Applications</h4>
              <Count>{adminLoanDashboard.TotalLoanApplication}</Count>
            </Row>
          </Cards>
          <Cards>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <h4>Applications Pending</h4>
              <Count>{adminLoanDashboard.TotalLoanApplicationPending}</Count>
            </Row>
          </Cards>
          <Cards>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <h4>Loans Active</h4>
              <Count>{adminLoanDashboard.TotalLoanActive}</Count>
            </Row>
          </Cards>
          <Cards>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <h4>Applications Closed</h4>
              <Count>{adminLoanDashboard.TotalLoanClosed}</Count>
            </Row>
          </Cards>
        </div>
        <CardTable style={{ marginTop: "20px" }}>
          <h4>Districtwise loan Applications</h4>
          <DataTable
            columns={adminDistrictwise}
            dataSource={adminLoanDashboard.DistrictWiseLoanApplication}
          />
        </CardTable>
        <CardTable style={{ marginTop: "20px" }}>
          <DataTable
            columns={SchemewiseLoanApplication}
            dataSource={adminLoanDashboard.SchemewiseloanApplications}
          />
        </CardTable>
      </div>
    ) : (
      <div>
        <h3>Dashboard</h3>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Loan",
              key: "1",
              children: (
                <>
                  <CardContainer>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Total Beneficiary</h4>
                        <Count>{dmDashboard?.TotalBeneficaryServed}</Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Total Application</h4>
                        <Count>{dmDashboard?.TotalLoanApplication}</Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Pending Application</h4>
                        <Count>
                          {dmDashboard?.TotalLoanApplicationPending}
                        </Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>DM Query</h4>
                        <Count>{dmDashboard?.TotalLoanDmQuery}</Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>DM Verified</h4>
                        <Count>{dmDashboard?.TotalLoanDmVerified}</Count>
                      </Row>
                    </Cards>
                  </CardContainer>
                  <Row
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <Cards style={{ marginLeft: "15px" }}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <h4>RM Reject</h4>
                        <Count>{dmDashboard?.TotalLoanRMReject}</Count>
                      </Row>
                    </Cards>
                    <Cards style={{ marginLeft: "30px" }}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          padding: "10px",
                        }}
                      >
                        <h4>Loans Active</h4>
                        <Count>{dmDashboard?.TotalLoanActive}</Count>
                      </Row>
                    </Cards>
                  </Row>
                  <CardsTable>
                    <h3>Districtwise Loan Application </h3>
                    <DataTable
                      columns={DistrictwiseLoanApplication}
                      dataSource={[dmDashboard?.DistrictWiseLoanApplication]}
                      pagination={{ pageSize: "4" }}
                    />
                  </CardsTable>
                  <CardsTable>
                    <h3>Schemewise Loan Application</h3>
                    <DataTable
                      columns={SchemewiseLoanApplication}
                      dataSource={dmDashboard?.SchemewiseloanApplications}
                      pagination={{ pageSize: "4" }}
                    />
                  </CardsTable>
                </>
              ),
            },
            {
              label: "Training",
              key: "2",
              children: (
                <>
                  <CardContainer>
                    {/* <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Total Beneficiary</h4>
                        <Count>{dmDashboard.TotalBeneficaryServed}</Count>
                      </Row>
                    </Cards> */}
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Application Recieved</h4>
                        <Count>{dmDashboard?.TotalTrainingApplication}</Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Pending Application</h4>
                        <Count>
                          {dmDashboard?.TotalTrainingApplicationPending}
                        </Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Shortlisted Application</h4>
                        <Count>
                          {
                            dmDashboard?.TrainingApplications
                              .TotalTrainingShortlistedApplication
                          }
                        </Count>
                      </Row>
                    </Cards>
                    <Cards>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <h4>Rejected Application</h4>
                        <Count>
                          {
                            dmDashboard?.TrainingApplications
                              .TotalTrainingRejectedApplication
                          }
                        </Count>
                      </Row>
                    </Cards>
                  </CardContainer>
                  {console.log([dmDashboard?.TrainingApplications])}
                  {/* <CardsTable>
                    <h3>Districtwise Training Application </h3>
                    <DataTable
                      columns={trainingApplication}
                      dataSource={[dmDashboard?.TrainingApplications]}
                      pagination={false}
                    />
                  </CardsTable> */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 50,
                      marginTop: "50px",
                    }}
                  >
                    {SSC_DASHBOARD?.data.trainingWiseecounts.map((data) => {
                      return (
                        <SSCCard
                          style={{
                            width: "330px",
                            height: "550px",
                            margin: 0,
                            padding: 0,
                            boxSizing: "border-box",
                          }}
                        >
                          {/* <Row>{data.categoryName}</Row>
       <Row>{data.totalApplicationCount}</Row> */}
                          <div
                            style={{
                              backgroundColor: "#E6F7FF",
                              width: "100%",
                              padding: 0,
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px 0",
                                margin: 0,
                                boxSizing: "border-box",
                              }}
                            >
                              <h3 style={{ margin: 0, padding: 0 }}>
                                {data.categoryName}
                              </h3>
                            </div>
                            <Row
                              style={{
                                marginTop: "12px",
                                display: "flex",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                boxSizing: "border-box",
                              }}
                            >
                              <Count>{data.totalApplicationCount}</Count>
                            </Row>
                          </div>
                          <Divider></Divider>
                          <div>
                            {data.trainingWiseecounts.map((data, index) => {
                              return (
                                <Row
                                  key={index}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <Col span={20}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "sans-serif",
                                        fontWeight: 500,
                                        margin: 0,
                                        padding: 0,
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      {data.trainingName}
                                    </p>
                                  </Col>
                                  <Col span={4}>
                                    <SSCCount>{data.count}</SSCCount>
                                  </Col>
                                  <Divider></Divider>
                                </Row>
                              );
                            })}
                          </div>
                        </SSCCard>
                      );
                    })}
                  </div>
                </>
              ),
            },
            // {
            //   label: "SSC",
            //   key: "3",
            //   children: (
            //     <>
            //       <Cards>
            //         <Row
            //           style={{
            //             display: "flex",
            //             flexDirection: "column",
            //             padding: "10px",
            //           }}
            //         >
            //           <h4>Total Application</h4>
            //           <Count>{SSC_DASHBOARD?.data.totalApplicationCount}</Count>
            //         </Row>
            //       </Cards>

            //     </>
            //   ),
            // },
          ]}
        />
      </div>
    );
  }
};
export default DistrictManagerDashboard;
