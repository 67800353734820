import React from "react";
import { Tabs } from "antd";
import { Button } from "antd";
import LoanFormAllData from "../../../../../../libs/common-ui/LoanManagement/LoanFormAllData";
import { MainContainer } from "../../../../style";
import axios from "axios";
// import { Token } from "../libs/utils/sessionStorage";
import { Token } from "../../../../../../libs/utils/sessionStorage";
import { REACT_APP_BASE_URL } from "../../../../../../libs/utils/urls";
const { TabPane } = Tabs;

const LoanScrunitypage = () => {
  const handlePendingDownload = () => {
    console.log("Download Excel for pending...");
    const apiUrl = `${REACT_APP_BASE_URL}/loan/XlsxScrutinyClerkDetailList/`;
    const statusParam = "SC";

    axios
      .get(apiUrl, {
        params: {
          status: statusParam,
        },
        responseType: "blob", // This tells Axios to expect a binary response
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${Token}`, // Assuming Token is the variable holding your access token
        },
      })
      .then((response) => {
        console.log(response);
        //  Create a temporary link element to trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "scruntinyPending.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
      });
  };

  const handleSubmittedDownload = () => {
    console.log("Download Excel for submitted...");
    const apiUrl = `${REACT_APP_BASE_URL}/loan/XlsxScrutinyClerkDetailList/`;
    const statusParam = "SC-QUERY";

    axios
      .get(apiUrl, {
        params: {
          status: statusParam,
        },
        responseType: "blob", // This tells Axios to expect a binary response
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${Token}`, // Assuming Token is the variable holding your access token
        },
      })
      .then((response) => {
        console.log(response);
        //  Create a temporary link element to trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "scruntinySubmitted.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
      });
  };

  const handleVerifiedDownload = () => {
    console.log("Download Excel for Verified...");
    console.log("Download Excel for submitted...");
    const apiUrl = `${REACT_APP_BASE_URL}/loan/XlsxScrutinyClerkDetailList/`;
    const statusParam = "DM";

    axios
      .get(apiUrl, {
        params: {
          status: statusParam,
        },
        responseType: "blob", // This tells Axios to expect a binary response
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${Token}`, // Assuming Token is the variable holding your access token
        },
      })
      .then((response) => {
        console.log(response);
        //  Create a temporary link element to trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "scruntinySubmitted.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
      });
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Scrunity-Pending" key="tab-a">
          <span style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button type="primary" onClick={handlePendingDownload}>
              {" "}
              Download Excel
            </Button>
          </span>
          <LoanFormAllData statusname="SC" heading="Scrunity-Pending" />
        </TabPane>
        <TabPane tab="Scrunity-Submitted-Query" key="tab-b">
          <span style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button type="primary" onClick={handleSubmittedDownload}>
              {" "}
              Download Excel
            </Button>
          </span>
          <LoanFormAllData
            statusname="SC-QUERY"
            heading="Scrunity-Submitted-Query"
          />
        </TabPane>
        <TabPane tab="Scrunity-Verified" key="tab-c">
          <span style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button type="primary" onClick={handleVerifiedDownload}>
              {" "}
              Download Excel
            </Button>
          </span>
          <LoanFormAllData statusname="DM" heading="Scrunity-Verified" />
        </TabPane>
      </Tabs>
    </>
  );
};

export default LoanScrunitypage;
