import React, { useEffect } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { useLocation } from "react-router";
import useTraining from "../../Constant/Container.js";
import {
  DataTable,
  PendingStatus,
  SelectedRow,
  SubmitButton,
} from "./style.js";
import { ApprovedStatus } from "../../../../cgm-side/style.js";
import { useState } from "react";
import AppicationForm from "../../Modals/ApplicationForm/index.js";
import { GrDocumentText } from "react-icons/gr";
import { Button, Checkbox, message } from "antd";

function AssignStudent() {
  let location = useLocation();
  const [
    trainingCount,
    handleDistrictChange,
    applicationList,
    handleDistrictChangeForApplication,
    handleTableChange,
    pagination,
    handleDistrictChangeForBatch,
    trainingList,
    batchList,
    fetchApplicationForBatch,
    applicationListForBatch,
    paginationForBatch,
    handleTableChangeForBatch,
    handleAssignBatch,
    fetchApplicationForAssignedBatch,
    applicationListForAssignedBatches,
    paginationForAssignedBatch,
    handleTableChangeForAssignedBatch,
  ] = useTraining();

  const [formData, setFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedStudent = [];
  const handleForOk = () => {
    setIsModalOpen(false);
  };
  const handleFormCancel = () => {
    setIsModalOpen(false);
  };

  const handleFormClick = (data) => {
    setIsModalOpen(true);
    setFormData(data);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleBatchSelectCheckBox = (data) => {
    const newSelectedRowKeys = selectedRowKeys.includes(data.id)
      ? selectedRowKeys.filter((key) => key !== data.id)
      : [...selectedRowKeys, data.id];
    console.log(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const [batchID, setBatchID] = useState();
  const handleBatchSubmit = (e) => {
    e.preventDefault();
    if (batchID !== "" && selectedRowKeys.length > 0) {
      handleAssignBatch(batchID, selectedRowKeys);
    } else {
      message.warning("Please select a students");
    }
  };
  const items = [
    {
      // title: "Select",
      render: (data) => {
        return (
          <>
            <Checkbox
              checked={selectedRowKeys.includes(data.id)}
              onChange={() => handleBatchSelectCheckBox(data)}
            />
          </>
        );
      },
    },
    {
      title: "Applicant's Name",
      dataIndex: "applicantName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Training Category",
      dataIndex: "trainingcategory",
    },
    {
      title: "Training Name",
      dataIndex: "trainingName",
    },
    {
      title: "District",
      dataIndex: "district",
    },
    // {
    //   title: "Batch",
    //   dataIndex: "trainingBatchName",
    // },
    {
      title: "Application Form",
      render: (data, index) => {
        return (
          <>
            <GrDocumentText
              onClick={() => handleFormClick(data)}
            ></GrDocumentText>
            {isModalOpen && (
              <AppicationForm
                isModalOpen={isModalOpen}
                handleFormOk={handleForOk}
                handleFormCancel={handleFormCancel}
                formData={formData}
              ></AppicationForm>
            )}
          </>
        );
      },
    },
    {
      title: "Approval",
      dataIndex: "trainingFinalStatus",
      render: (status) => {
        if (status == "Pending") {
          return <PendingStatus>{status}</PendingStatus>;
        } else if (status == "Approved") {
          return <ApprovedStatus>{status}</ApprovedStatus>;
        } else if (status == "Rejected") {
          return <PendingStatus>{status}</PendingStatus>;
        }
      },
    },
  ];

  useEffect(() => {
    // console.log(location.state.data.BatchtrainingName_id);
    setBatchID(location.state.data.id);
    fetchApplicationForBatch( 
      location.state.data.district,
      location.state.data.trainingName
    );
  }, []);

  const onRow = (record) => ({
    onClick: () => {
      handleBatchSelectCheckBox(record);
    },
  });
  const rowClassName = (record) => {
    return selectedRowKeys.includes(record.id) ? "selected-row" : "";
  };

  const components = {
    body: {
      row: ({ className, ...props }) => {
        if (className.includes("selected-row")) {
          return <SelectedRow {...props} />;
        }
        return <tr {...props} />;
      },
    },
  };
  return (
    <>
      <DataTable
        columns={items}
        dataSource={applicationListForBatch}
        pagination={paginationForBatch}
        rowKey="id"
        rowClassName={rowClassName}
        components={components}
        onRow={onRow}
        onChange={handleTableChangeForBatch}
      ></DataTable>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SubmitButton type="primary" onClick={handleBatchSubmit}>
          Submit
        </SubmitButton>
      </div>
    </>
  );
}

export default AssignStudent;
