import React, { useEffect, useState } from "react";
import useTraining from "./container";
import { FormItem, LoadContainer, MainContainer } from "./style";
import { Form, Select, Spin } from "antd";
import AllTraining from "../carousels/allTraining";
import { Option } from "antd/lib/mentions";
import data from "../../../data/dtdata.json";
import { fetchTrainingList, fetchUserInfo } from "../../api/commonapi";
const Training = () => {
  // const { loading } = useTraining();
  const [loading, setLoading] = useState(false);
  const [trainingList, setTrainingList] = useState();

  const [numOfTraining, setNumOfTraining] = useState();
  const [trainingLoading, setTrainingLoading] = useState(true);
  const [district, setDistrict] = useState();

  useEffect(() => {
    setDistrict(sessionStorage.getItem("district"));
    getTrainingList();
  }, []);
  const getTrainingList = async (data = sessionStorage.getItem("district")) => {
    setLoading(true);
    const response = await fetchTrainingList(data);
    setTrainingList(response);
    setNumOfTraining(response.count);
    setTrainingLoading(false);
    setLoading(false);
  };
  // const getUserInfo = async () => {
  //   setLoading(true);
  //   console.log("getUserInfo");
  //   const response = await fetchUserInfo();
  //   console.log(response);
  //   setUserInfo(response);
  //   setLoading(false);
  // };

  if (loading === true) {
    return (
      <LoadContainer>
        <Spin tip="Loading data..." />
      </LoadContainer>
    );
  } else {
    return (
      <MainContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Latest Training to Apply</h2>
          <div>
            <Form layout="vertical">
              <FormItem label="Select District">
                <Select
                  showSearch
                  placeholder="Select district"
                  optionFilterProp="children"
                  onChange={(v, k) => {
                    setDistrict(v);
                    // setTalukaKey(k.key);
                    // setSelectVisible(false);
                    console.log(v);
                    getTrainingList(v);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={district}
                  style={{ width: "250px" }}
                  name="district"
                >
                  {data.map((dis, index) => {
                    return (
                      <Option
                        key={index}
                        value={dis.district_name}
                        // onChange={(e)=>setDistrict(e)}
                      >
                        {dis.district_name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>
        <AllTraining
          trainingList={trainingList}
          trainingLoading={trainingLoading}
          numOfTraining={numOfTraining}
          loading={loading}
          setLoading={setLoading}
        />
      </MainContainer>
    );
  }
};
export default Training;
