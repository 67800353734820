export const MH_ROUTES = {
  JOB: {
    JOB_LISTING: "/AllVerticalWiseJobApplicantList",
  },
  DASHBOARD: {
    MAHAPREIT_DASHBOARD: "/mahapreitOfficeAdminDashboard",
  },
  SCHEMES: {
    ALL_SCHEME_APPLICATION: "/MahapreitOfficeAdminSpecialSchemebApplicantList",
  },
};
