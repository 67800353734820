import styled from "styled-components";
export const TranslateContainer = styled.div`
  margin: 0px 10px;
  .goog-te-gadget .goog-te-combo {
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    color: #404040;
  }
  #text {
    display: none;
  }
`;
