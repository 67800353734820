import Dashboard from "../../../../cgm-side";

export const SSCRoutes={
    Dashboard:{
        DashboardCount:"/SectorSkillWiseDashboard"
    },
    List:{
      ApplicationList:"/SectorSkillWiseApplicationList"
    },
    BATCH:{
      CREATE_TRAINING_BATCH: "/createTrainingBatch",
      GETTRAININGLIST: "/gettraininglist",
      GETBATCHLIST: "/listTrainingBatches",
      ASSIGNEDBATCH:"/AssignedBatchAPIView/"
    }

}