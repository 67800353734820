export const DistrictwiseLoanApplication = [
  {
    title: "District",
    dataIndex: "District",
  },
  {
    title: "Application Count",
    dataIndex: "TotalCount",
  },
];
export const SchemewiseLoanApplication = [
  {
    title: "Scheme",
    dataIndex: "LoanScheme",
  },
  {
    title: "Application Count",
    dataIndex: "TotalCount",
  },
];
