export const MpbcdcRoutes = {
  DASHBOARD: "/mpbcdcOffceAdminDashboard",
 
  TRAINING: {
    DASHBOARD: "/NewmpbcdcOffceAdminDashboard",
    TRAINING_LIST: "/gettrainingapplicationlist/",
  },
  LOAN: {
    DASHBOARD: "/mpbcdcOffceAdminDashboard",
    LOAN_APPLICATION_SCHEMEWISE: "/SchemewiseCount",
  },
};
