import { message } from "antd";
import { trainingwAuthInstance } from "../../../../libs/utils/fetch-utils";
import { SSCRoutes } from "../Constant/Routes";

export const FetchTrainingDashboard = async (districtName = "") => {
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.Dashboard.DashboardCount}`,
    {
      params: {
        district: districtName,
      },
    }
  );

  console.log(response.data.data, "SSC Dashboard Count");
  return response.data.data;
};

export const FetchTrainingApplications = async (
  districtName = "",
  page = 1
) => {
  console.log(page);
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.List.ApplicationList}`,
    districtName !== ""
      ? {
          params: {
            district: districtName,
            page: page,
          },
        }
      : {
          params: {
            page: page,
          },
        }
  );
  return response;
};
export const FetchTrainingApplicationsForBatch = async (
  districtName = "",
  trainingName = "",
  page = 1
) => {
  console.log(page);
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.List.ApplicationList}`,
    districtName !== ""
      ? {
          params: {
            district: districtName,
            IsBatchAssigned: 0,
            trainingName: trainingName,
            page: page,
          },
        }
      : {
          params: {
            IsBatchAssigned: 0,
            trainingName: trainingName,
            page: page,
          },
        }
  );
  return response;
};
export const FetchTrainingApplicationsForAssignedBatch = async (
  districtName = "",
  trainingName = "",
  batchId = "",
  page = 1
) => {
  console.log(page);
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.List.ApplicationList}`,

    {
      params: {
        district: districtName,
        trainingName: trainingName,
        batchId: batchId,
        IsBatchAssigned: 1,
        page: page,
      },
    }
  );
  return response;
};
export const handleBatchCreation = async (data) => {
  const response = await trainingwAuthInstance.post(
    `${SSCRoutes.BATCH.CREATE_TRAINING_BATCH}`,
    data
  );
  return response;
};
export const FetchTrainingList = async (districtName) => {
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.BATCH.GETTRAININGLIST}`,
    {
      params: {
        district: districtName,
        category: sessionStorage.getItem("sectorSkillName"),
      },
    }
  );
  return response.data;
};

export const FetchBatchList = async () => {
  const response = await trainingwAuthInstance.get(
    `${SSCRoutes.BATCH.GETBATCHLIST}`,
    {
      params: {
        category: sessionStorage.getItem("sectorSkillName"),
      },
    }
  );
  return response.data;
};

export const AssignBatch = async (batch, students) => {
  const response = await trainingwAuthInstance.patch(
    `${SSCRoutes.BATCH.ASSIGNEDBATCH}`,
    {
      AssignedBatch: batch,
      appids: students,
    }
  );
  return response;
};
