import { useEffect, useState } from "react";
import {
  AssignBatch,
  FetchBatchList,
  FetchTrainingApplications,
  FetchTrainingApplicationsForAssignedBatch,
  FetchTrainingApplicationsForBatch,
  FetchTrainingDashboard,
  FetchTrainingList,
} from "../../Api/CommonApi";
import { message } from "antd";

const useTraining = () => {
  const [trainingCount, setTrainingCount] = useState();
  const [applicationList, setApplicationList] = useState([]);
  const [applicationListForBatch, setApplicationListForBatch] = useState([]);
  const [
    applicationListForAssignedBatches,
    setApplicationListForAssignedBatches,
  ] = useState([]);
  const [district, setDistrict] = useState();
  const [trainingName, setTrainingName] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });
  const [paginationForBatch, setPaginationForBatch] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });
  const [paginationForAssignedBatch, setPaginationForAssignedBatch] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });
  const [trainingList, setTrainingList] = useState([]);
  const [batchList, setBatchList] = useState([]);

  const fetchTrainingCount = async () => {
    const data = await FetchTrainingDashboard();
    setTrainingCount(data);
  };
  const fetchApplicationList = async () => {
    const data = await FetchTrainingApplications();
    console.log(data.data.data.pagination, "application list");
    setPagination({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });
    setApplicationList(data.data.data.totalApplicationList);
  };
  const handleDistrictChange = async (district) => {
    const data = await FetchTrainingDashboard(district);
    console.log(data, "district check");
    setTrainingCount(data);
  };
  const handleDistrictChangeForApplication = async (district) => {
    setDistrict(district);
    const data = await FetchTrainingApplications(district);
    setPagination({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });
    setApplicationList(data.data.data.totalApplicationList);
  };
  const handleTableChange = async (pagination) => {
    console.log(pagination.current);
    const data = await FetchTrainingApplications(district, pagination.current);

    setPagination({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });
    setApplicationList(data.data.data.totalApplicationList);
  };
  const handleDistrictChangeForBatch = async (district) => {
    setDistrict(district);
    const data = await FetchTrainingList(district);
    console.log(data.results);
    setTrainingList(data.results);
  };
  const fetchBatchList = async () => {
    const data = await FetchBatchList();
    setBatchList(data);
  };
  const fetchApplicationForBatch = async (district, trainingName) => {
    setDistrict(district);
    setTrainingName(trainingName);
    const data = await FetchTrainingApplicationsForBatch(
      district,
      trainingName
    );
    setPaginationForBatch({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });

    setApplicationListForBatch(data.data.data.totalApplicationList);
  };
  const handleTableChangeForBatch = async (pagination) => {
    console.log(pagination.current);
    const data = await FetchTrainingApplicationsForBatch(
      district,
      trainingName,
      pagination.current
    );

    setPaginationForBatch({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });

    setApplicationListForBatch(data.data.data.totalApplicationList);
  };

  const handleAssignBatch = async (batch, student) => {
    const response = await AssignBatch(batch, student);

    if (response.data.status == "success") {
      message.success(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      message.warning(response.data.message);
    }
  };

  const fetchApplicationForAssignedBatch = async (
    district,
    trainingName,
    batchName
  ) => {
    setDistrict(district);
    const data = await FetchTrainingApplicationsForAssignedBatch(
      district,
      trainingName,
      batchName
    );
    setPaginationForAssignedBatch({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });
    setApplicationListForAssignedBatches(data.data.data.totalApplicationList);
  };
  const handleTableChangeForAssignedBatch = async (pagination) => {
    console.log(pagination.current);
    const data = await FetchTrainingApplicationsForAssignedBatch(
      district,
      pagination.current
    );

    setPaginationForAssignedBatch({
      ...pagination,
      current: data.data.data.pagination.current_page,
      total: data.data.data.pagination.count,
    });

    setApplicationListForAssignedBatches(data.data.data.totalApplicationList);
  };

  useEffect(() => {
    fetchTrainingCount();
    fetchApplicationList();
    fetchBatchList();
  }, []);

  return [
    trainingCount,
    handleDistrictChange,
    applicationList,
    handleDistrictChangeForApplication,
    handleTableChange,
    pagination,
    handleDistrictChangeForBatch,
    trainingList,
    batchList,
    fetchApplicationForBatch,
    applicationListForBatch,
    paginationForBatch,
    handleTableChangeForBatch,
    handleAssignBatch,
    fetchApplicationForAssignedBatch,
    applicationListForAssignedBatches,
    paginationForAssignedBatch,
    handleTableChangeForAssignedBatch,
  ];
};

export default useTraining;
