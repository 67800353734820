import React from "react";
import useMpbcdc from "../container";
import { LoadContainer } from "../../../../../user-side/style";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Option } from "antd/lib/mentions";
import e from "cors";
import data from "../../../../../../data/dtdata.json";
import useTraining from "../../../../../dm-side/src/app/pages/container";
import { Count, SSCCard, SSCCount, TotalTrainingCards } from "../../../../style";

const columns = [
  { title: "District", dataIndex: "district" },
  { title: "Loan Applications", dataIndex: "LoanApplicationCount" },
];

const trainingCol = [
  { title: "District", dataIndex: "district" },
  { title: "Total Count", dataIndex: "trainingApplicationCount" },
  { title: "Pending", dataIndex: "TotalTrainingApplicationPending" },
  { title: "Sortlisted", dataIndex: "TotalTrainingApplicationShortlisted" },
  { title: "Selected", dataIndex: "TotalTrainingApplicationSelected" },
  { title: "Rejected", dataIndex: "TotalTrainingApplicationRejected" },
];
const SchemesColumns = [
  "Retail Sales Associate",
  "Multi Skill Technician (Electrical)",
  "General Housekeeper (Household & Small Establishment)",
  "Service Technician - Home Appliances",
  "Hair Dresser and Stylist",
  "E-Commerce Delivery Executive",
  "IT Coordinator in School",
  "Mobile Phone Hardware Repair Technician",
  "Credit Processing Officer",
  "Business Correspondent / Facilitator",
  "Customer care executive",
  "Business Correpondent / Facilitator",
  "Laundry Service Provider",
  "Housekeeper cum Cook (Household & Small Establishment)",
  "Professional Makeup Artist",
  "Beauty Therapist",
  "Warehouse Packer",
];
const Dashboard = () => {
  const {
    loanDashboardData,
    trainingDashboard,
    dashboardLoading,
    getTrainingDashboardData,
  } = useMpbcdc();
  const { SSC_DASHBOARD } = useTraining();

  if (dashboardLoading == true) {
    return (
      <LoadContainer>
        <Spin tip="Loading data" />
      </LoadContainer>
    );
  } else {
    return (
      <div>
        <h3>Dashboard</h3>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Loan",
              key: "1",
              children: (
                <>
                  <Row gutter={24}>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4> Total Loan Applications</h4>
                        <h2>{loanDashboardData?.TotalLoanApplication}</h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Pending Loan Applications</h4>
                        <h2>
                          {loanDashboardData?.TotalLoanPendingApplication}
                        </h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Active Loans</h4>
                        <h2>{loanDashboardData?.ActiveLoanCount}</h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Total Loans</h4>
                        <h2>{loanDashboardData?.TotalLoan}</h2>
                      </Cards>
                    </Col>
                  </Row>

                  <Card style={{ marginTop: "20px" }}>
                    <h4>Districtwise Application Counts</h4>
                    <Table
                      columns={columns}
                      dataSource={loanDashboardData?.DistrictWiseCounts}
                      pagination={{ pageSize: 5 }}
                    />
                  </Card>
                </>
              ),
            },
            {
              label: "Training",
              key: "2",
              children: (
                <>
                  <Row gutter={24}>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Total Training Applications</h4>
                        <h2>{trainingDashboard?.TotalTrainingApplication}</h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Pending Training Applications</h4>
                        <h2>
                          {trainingDashboard?.TotalTrainingApplicationPending}
                        </h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Active Training</h4>
                        <h2>{trainingDashboard?.ActiveTrainingCount}</h2>
                      </Cards>
                    </Col>
                    <Col span={6}>
                      <Cards bordered={false}>
                        <h4>Total Training</h4>
                        <h2>{trainingDashboard?.TotalTrainingActive}</h2>
                      </Cards>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      margin: "15px 15px -15px 0px ",
                    }}
                  >
                    <Form layout="vertical">
                      <Row>
                        {/* <Form.Item
                          label="Select District"
                          style={{ marginRight: "20px" }}
                        >
                          <Select
                            showSearch
                            placeholder="Select district"
                            optionFilterProp="children"
                            onChange={(v, k) => {
                              // setDistrict(v);
                              // setTalukaKey(k.key);
                              // setSelectVisible(false);
                              console.log(v);
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // value={district}
                            style={{ width: "250px" }}
                            name="district"
                          >
                            {data.map((dis, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={dis.district_name}
                                  // onChange={handleDistrict}
                                >
                                  {dis.district_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item> */}
                        <Form.Item label="Select Training">
                          <Select
                            showSearch
                            style={{ width: "250px" }}
                            placeholder="Select Training Scheme"
                            onChange={(value) =>
                              getTrainingDashboardData({ trainingname: value })
                            }
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {SchemesColumns.map((data, index) => (
                              <Option key={index} value={data}>
                                {data}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Row>
                    </Form>
                  </div>
                  <Card style={{ marginTop: "20px" }}>
                    <h4>Districtwise Training Application</h4>
                    <Table
                      columns={trainingCol}
                      dataSource={
                        trainingDashboard?.DistrictWiseTrainingApplicationCounts
                      }
                      pagination={{ pageSize: 5 }}
                    />
                  </Card>
                </>
              ),
            },
            {
              label: "SSC",
              key: "3",
              children: (
                <>
                  <TotalTrainingCards>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                      }}
                    >
                      <h4>Total Application</h4>
                      <Count>{SSC_DASHBOARD?.data.totalApplicationCount}</Count>
                    </Row>
                  </TotalTrainingCards>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 50,
                      marginTop: "50px",
                    }}
                  >
                    {SSC_DASHBOARD?.data.trainingWiseecounts.map((data) => {
                      return (
                        <SSCCard
                          style={{
                            width: "350px",
                            height: "550px",
                            margin: 0,
                            padding: 0,
                            boxSizing: "border-box",
                          }}
                        >
                          {/* <Row>{data.categoryName}</Row>
       <Row>{data.totalApplicationCount}</Row> */}
                          <div
                            style={{
                              backgroundColor: "#E6F7FF",
                              width: "100%",
                              padding: 0,
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px 0",
                                margin: 0,
                                boxSizing: "border-box",
                              }}
                            >
                              <h3 style={{ margin: 0, padding: 0 }}>
                                {data.categoryName}
                              </h3>
                            </div>
                            <Row
                              style={{
                                marginTop: "12px",
                                display: "flex",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                boxSizing: "border-box",
                              }}
                            >
                              <Count>{data.totalApplicationCount}</Count>
                            </Row>
                          </div>
                          <Divider></Divider>
                          <div>
                            {data.trainingWiseecounts.map((data, index) => {
                              return (
                                <Row
                                  key={index}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <Col span={20}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "sans-serif",
                                        fontWeight: 500,
                                        margin: 0,
                                        padding: 0,
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      {data.trainingName}
                                    </p>
                                  </Col>
                                  <Col span={4}>
                                    <SSCCount>{data.count}</SSCCount>
                                  </Col>
                                  <Divider></Divider>
                                </Row>
                              );
                            })}
                          </div>
                        </SSCCard>
                      );
                    })}
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    );
  }
};
export default Dashboard;

export const Cards = styled(Card)`
  height: 100px;
`;
