import { useEffect, useState } from "react";
import {
  fetchDashboardData,
  fetchLoanApplicationSchemesWise,
  fetchLoanDashboardData,
  fetchTrainingApplicationList,
  fetchTrainingDashboardData,
} from "../api/commonapi";

const useMpbcdc = () => {
  const [trainingApplicationList, setTrainingApplicationList] = useState();
  const [loanApplicationList, setLoanApplicationList] = useState();

  const [trainingDashboard, setTrainingDashboard] = useState(null);
  const [loanDashboardData, setLoanDashboardData] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);

  const getTrainingApplicationList = async () => {
    const response = await fetchTrainingApplicationList();
    setTrainingApplicationList(response);
  };
  const getLoanApplicationList = async () => {
    const response = await fetchLoanApplicationSchemesWise();
    setLoanApplicationList(response);
  };

  const getTrainingDashboardData = async (params = {}) => {
    const response = await fetchTrainingDashboardData(params);
    console.log(response);
    setTrainingDashboard(response);
    setDashboardLoading(false);
  };
  const getLoanDashboardData = async () => {
    try {
      const response = await fetchLoanDashboardData();
      // console.log("Loan Dashboard Data:", response);
      setLoanDashboardData(response);
    } catch (error) {
      // console.error("Error fetching loan dashboard data:", error);
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    getTrainingApplicationList();
    getLoanApplicationList();
    getLoanDashboardData();
    getTrainingDashboardData();
  }, []);
  useEffect(() => {
    console.log("Loan Dashboard Data after effect:", loanDashboardData);
  }, [loanDashboardData]);

  return {
    trainingApplicationList,
    loanApplicationList,
    loanDashboardData,
    trainingDashboard,
    dashboardLoading,
    getTrainingDashboardData,
  };
};
export default useMpbcdc;
