import { Button, Form, Table } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin: 2rem;
`;
export const FormContainer = styled.div`
  margin: 1rem;
`;
export const FormItem = styled(Form.Item)`
  display: flex;
  width: 25rem;
  flex-direction: column;
  .ant-form-item-label {
    text-align: left;
    margin-bottom: 5px;
  }
`;
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 5rem;
`;
export const BatchListContainer = styled.div`
  margin-top: 2rem;
`;
export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`;
export const TableContainer = styled.div``;
export const DataTable = styled(Table)`
  margin-top: 15px;
  .ant-table-pagination.ant-pagination {
    margin: 20px 0px;
  }
  .ant-table-thead > tr > th {
    padding: 10px 15px;
  }
  .ant-table {
    overflow-x: scroll;
  }
  .ant-table-tbody > tr > td {
    padding: 15px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
`;

export const ViewButton = styled(Button)`
  border: none;
`;

export const AssignIcon = styled(Button)`
  border: none;
`;
