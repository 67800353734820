import React, { useEffect, useState } from "react";
import { FetchTrainingDashboard } from "../../Api/CommonApi";
import {
  CardContainer,
  Cards,
  Count,
  FormItem,
  MainContainer,
} from "../../../style";
import { Card, Form, Row, Select } from "antd";
import useTraining from "../../Constant/Container.js";
import data from "../../../../../data/dtdata.json";
import { Option } from "antd/lib/mentions";

const SectoreSkillCouncilDashboard = () => {
  const [dashboardCount, handleDistrictChange] = useTraining();

  const [district, setDistrict] = useState();

  return (
    <>
      <MainContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Cards style={{ width: "300px", height: "100px" }}>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "2px",
              }}
            >
              <h2>Total Application</h2>
              <Count>{dashboardCount?.totalApplicationCount}</Count>
            </Row>
          </Cards>

          <Form layout="vertical">
            <FormItem label="Select District">
              <Select
                showSearch
                placeholder="Select district"
                optionFilterProp="children"
                onChange={(v, k) => {
                  setDistrict(v);
                  // setTalukaKey(k.key);
                  // setSelectVisible(false);
                  handleDistrictChange(v);

                  console.log(v);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                value={district}
                style={{ width: "250px" }}
                name="district"
              >
                {data.map((dis, index) => {
                  return (
                    <Option
                      key={index}
                      value={dis.district_name}
                      // onChange={(e)=>setDistrict(e)}
                    >
                      {dis.district_name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Form>
        </div>

        <div style={{ margin: "30px 0px 10px 10px" }}>
          <h2>Training wise count</h2>
        </div>
        <CardContainer>
          {dashboardCount?.trainingWiseecounts.map((data) => {
            return (
              <Cards>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    padding: "5px",
                  }}
                >
                  <h4>{data?.training__trainingName}</h4>
                  <Count>{data?.count}</Count>
                </Row>
              </Cards>
            );
          })}
        </CardContainer>
      </MainContainer>
    </>
  );
};
export default SectoreSkillCouncilDashboard;
