import React, { useState, useRef, useEffect, useCallback } from "react";
import useTraining from "./container";
import { InputFields, MainContainer } from "./style";
import {
  Spin,
  Row,
  DatePicker,
  Button,
  message,
  Col,
  Checkbox,
  Select,
  Form,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { REACT_APP_BASE_URL } from "../../utils/urls";
import axios from "axios";
import { Token } from "../../utils/sessionStorage";
import { useLocation } from "react-router";
import { fetchUserInfo } from "../../api/commonapi";
import ImageViewer from "react-simple-image-viewer";
import stateData from "../../../data/states.json";
import data from "../../../data/dtdata.json";
import { SelectDropDown } from "../../../apps/user-side/src/app/pages/profile/editProfile/style";

const { Option } = Select;
const FormItem = Form.Item;

const TrainingForm = React.memo(() => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trainingId = queryParams.get("trainingId");
  console.log(trainingId);

  const formData = useRef();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [applicantName, setApplicantName] = useState("");
  const [gender, setgender] = useState("");
  const [aadharLinkBankName, setAadharLinkBankName] = useState("");
  const [aadharLinkBankAccountNumber, setAadharLinkBankAccountNumber] =
    useState("");
  const [aadharLinkBankifscCode, setAadharLinkBankifscCode] = useState("");
  const [panCardNumber, setpanCardNumber] = useState("");
  const [detailsOfApplicantsExperience, setdetailsOfApplicantsExperience] =
    useState("");
  const [incomeCertifcate, setIncomeCertificate] = useState("");
  const [rationCard, setRationCard] = useState("");
  const [leavingCertificate, setLeavingCertificate] = useState("");
  const [casteCertificate, setCasteCertificate] = useState(null);
  const [aadharCardNumber, setAadharCardNumber] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [bankPassBook, setBankPassBook] = useState("");
  const [passportPhoto, setPassportPhoto] = useState("");
  const [residingValue, setResidingValue] = useState();
  const [consentChecked, setConsentChecked] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [educationListTechnical, setEducationListTechnical] = useState([]);

  const [selectedGradeTechnical, setSelectedGradeTechnical] = useState(null);

  const fullName = sessionStorage.getItem("fullName");

  const [addressValue, setAddressValue] = useState("");
  const handleAddressValue = (e) => {
    setAddressValue(e.target.value);
  };
  const [district, setDistrict] = useState("");
  const handleDistrict = (e) => {
    setDistrict(e.target.value);
  };
  const [talukaKey, setTalukaKey] = useState(0);
  const [selectVisible, setSelectVisible] = useState(true);
  const [taluka, setTaluka] = useState("");
  const [pinCode, setPincode] = useState("");
  const handlePincode = (e) => {
    setPincode(e.target.value);
  };

  const [areaType, setAreaType] = useState("");

  const handleAreaTypeChange = (value) => {
    setAreaType(value);
  };

  const incomeCertificateChange = useCallback((event) => {
    setIncomeCertificate(event.target.files[0]);
  }, []);

  const rationCardChange = useCallback((event) => {
    setRationCard(event.target.files[0]);
  }, []);

  const leavingCertificateChange = useCallback((event) => {
    setLeavingCertificate(event.target.files[0]);
  }, []);

  const casteCertificateChange = useCallback((event) => {
    setCasteCertificate(event.target.files[0]);
  }, []);

  const bankPassBookChange = useCallback((event) => {
    setBankPassBook(event.target.files[0]);
  }, []);

  const aadharCardChange = useCallback((event) => {
    setAadharCard(event.target.files[0]);
  }, []);

  const handleAadharCardNumberChange = useCallback((e) => {
    const regex = /^[2-9][0-9]{0,11}$/; // Regex pattern: cannot start with 0 or 1
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAadharCardNumber(e.target.value);
    }
  }, []);

  const handlePassportPhotoChange = useCallback((event) => {
    setPassportPhoto(event.target.files[0]);
  }, []);

  const handleChange = useCallback((e) => {
    setResidingValue(e.target.value);
  }, []);

  const handleCheckboxChange = useCallback((e) => {
    setConsentChecked(e.target.checked);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // const {
      //   education,
      //   yearOfLeavingSchool,
      //   aadharLinkBankName,
      //   aadharLinkBankAccountNumber,
      //   aadharLinkBankifscCode,
      //   panCardNumber,
      //   inWhichGradeLeftSchool,
      //   detailsOfApplicantsExperience,
      // } = formData.current;
      const trainingData = new FormData();
      trainingData.append("qualification", selectedGradeTechnical);
      trainingData.append("applicantName", applicantName);
      trainingData.append("gender", gender);
      trainingData.append("address", addressValue);
      trainingData.append("taluka", taluka);
      trainingData.append("district", district);
      trainingData.append("aadharCardNo", aadharCardNumber);
      trainingData.append("aadharLinkBankName ", aadharLinkBankName);
      trainingData.append(
        "aadharLinkBankAccountNumber ",
        aadharLinkBankAccountNumber
      );
      trainingData.append("aadharLinkBankifscCode ", aadharLinkBankifscCode);
      // trainingData.append("passportSizePhoto ", passportPhoto);

      trainingData.append("training", trainingId);

      trainingData.append("incomeCertificate", incomeCertifcate);

      trainingData.append("rationCard", rationCard);
      trainingData.append(
        "schoolOrCollegeLeavingCertificate",
        leavingCertificate
      );
      trainingData.append("casteCertificate", casteCertificate);
      trainingData.append("aadharCard", aadharCard);
      trainingData.append("bankPassBook", bankPassBook);
      trainingData.append("passportSizePhoto", passportPhoto);
      trainingData.append("areaType", areaType);

      if (selectedGradeTechnical === null) {
        message.warning("Enter the Education");
      } else if (applicantName === "") {
        message.warning("Enter applicant name");
      } else if (gender === "") {
        message.warning("Select gender");
      } else if (addressValue === "") {
        message.warning("Enter Address");
      } else if (district === "") {
        message.warning("Select district");
      } else if (taluka === "") {
        message.warning("select taluka");
      } else if (areaType === "") {
        console.log(typeof areaType);
        message.warning("Select Urban/Rural area type");
      } else if (aadharCardNumber === "") {
        message.warning("Enter aadhar card number");
      } else if (aadharLinkBankName === "") {
        message.warning("Enter aadhar linked bank name");
      } else if (aadharLinkBankAccountNumber === "") {
        message.warning("Enter aadhar linked bank account number");
      } else if (aadharLinkBankifscCode === "") {
        message.warning("Enter aadhar linked bank ifsc code");
      } else if (
        leavingCertificate === null ||
        typeof leavingCertificate != "object"
      ) {
        message.warning(
          "Please Upload School/College Marksheet/Leaving Certificate"
        );
      } else if (
        casteCertificate === null ||
        typeof casteCertificate != "object"
      ) {
        message.warning("Please Upload Caste Certificate");
      } else if (aadharCard === null || typeof aadharCard != "object") {
        message.warning("Please Upload Aadhar Card");
      } else if (!consentChecked) {
        message.warning("Please provide consent before submitting the form");
      } else if (bankPassBook === null || typeof bankPassBook != "object") {
        message.warning("Please Upload Bank Pass Book");
      } else if (passportPhoto === null || typeof passportPhoto != "object") {
        message.warning("Please Upload Passport size photo");
      } else {
        setLoading(true);
        axios({
          method: "post",
          url: `${REACT_APP_BASE_URL}/training/ApplyNewTrainingAPIView/`,
          data: trainingData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${Token}`,
          },
        })
          .then((response) => {
            setLoading(false);
            if (response.status === 200 && response.data.status === "success") {
              message.success(response.data.message);
              setTimeout(() => {
                window.location.replace("/user-training-application");
              }, 1000);
            } else if (
              response.status === 200 &&
              response.data.status === "error"
            ) {
              message.error(response.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            message.error(error.message);
          });
      }
    },
    [
      areaType,
      selectedGradeTechnical,
      gender,
      aadharCardNumber,
      incomeCertifcate,
      rationCard,
      leavingCertificate,
      casteCertificate,
      aadharCard,
      bankPassBook,
      passportPhoto,
      consentChecked,
    ]
  );

  const fetchEducationList = useCallback(() => {
    axios
      .get("https://bhugolapps.com/training/get-education-list")
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          const dataArray = Object.values(response.data.data);
          setEducationList(dataArray);
          setEducationListTechnical(dataArray);
        } else {
          console.error(
            "Failed to fetch education list:",
            response.data.message || response.statusText
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching education list:", error.message);
      });
  }, []);

  useEffect(() => {
    fetchEducationList();
  }, [fetchEducationList]);

  const handleGenderChange = useCallback((value) => {
    setgender(value);
  }, []);

  const handleEducationChange = useCallback((value) => {
    setSelectedGradeTechnical(value);
  }, []);

  const getUserInfo = async () => {
    const response = await fetchUserInfo();
    console.log(response, "getUserInfo");
    setUserInfo(response);
    console.log(response.aadharNumber);
    setAadharCardNumber(response.aadharNumber);
    setAadharLinkBankName(response.CustomUserBankInfo[0].bankName);
    setAadharLinkBankAccountNumber(
      response.CustomUserBankInfo[0].bankAccountNo
    );
    setAadharLinkBankifscCode(response.CustomUserBankInfo[0].ifscCode);
    setAddressValue(response.CustomUserResidentialInfo[0].address);
    setDistrict(response.CustomUserResidentialInfo[0].district);
    setTaluka(response.CustomUserResidentialInfo[0].taluka);
    setPincode(response.CustomUserResidentialInfo[0].pinCode);
    setApplicantName(response.UserPersonalInfo[0].name);
    // setSelectedGradeTechnical(response.CustomUserQualificationInfo[0].qualificationLevel)

    // setIncomeCertificate(
    //   response.CustomUserIncomeAndDomicileInfo[0].incomeCertificate
    // );
    // setCasteCertificate(response.UserPersonalInfo[0].casteCertificate);
    // console.log(response.UserPersonalInfo[0].casteCertificate);
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback(() => {
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <Spin tip="Submitting Form.." spinning={loading}>
      <MainContainer>
        {isViewerOpen && (
          <ImageViewer
            src={[REACT_APP_BASE_URL + incomeCertifcate]}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}
        <div style={{ marginBottom: "5px" }}>
          <h2>Training Application Form</h2>
        </div>
        <Form ref={formData} layout="vertical">
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}></Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}>
              <FormItem label="Education" required>
                <Select
                  placeholder="Education"
                  style={{ width: "220px" }}
                  onChange={handleEducationChange}
                  value={selectedGradeTechnical}
                  name="Education"
                >
                  {educationListTechnical.map((grade, index) => (
                    <Option key={index} value={grade}>
                      {grade}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Applicant Name (As per aadhar card)" required>
                <InputFields
                  type="text"
                  name="applicantName"
                  value={applicantName}
                  onChange={(e) => setApplicantName(e.target.value)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Gender" required>
                <Select
                  placeholder="Select gender"
                  style={{ width: "220px" }}
                  onChange={handleGenderChange}
                  value={gender}
                  name="gender"
                >
                  <Option value="male">male</Option>
                  <Option value="female">female</Option>
                  <Option value="transgender">transgender</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem
                label="Address"
                required
                // name="address"
                // rules={[
                //   { required: true, message: "Please enter your Address" },
                // ]}
              >
                <TextArea
                  placeholder="Please input your Address"
                  name="address"
                  value={addressValue}
                  onChange={handleAddressValue}
                  // defaultValue={residentialDetails.address}
                  style={{ width: "250px" }}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="State"
                name="State"
                rules={[
                  {
                    required: true,
                    message: "Please enter your state",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select state"
                  optionFilterProp="children"
                  style={{ width: "250px" }}
                  name="state"
                  defaultValue="Maharashtra"
                  disabled
                >
                  {stateData.map((state, index) => {
                    return (
                      <Option key={index} value={state.name}>
                        {state.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="District"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your District!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select district"
                  optionFilterProp="children"
                  onChange={(v, k) => {
                    setDistrict(v);
                    setTalukaKey(k.key);
                    setSelectVisible(false);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={district}
                  style={{ width: "250px" }}
                  name="district"
                  // defaultValue={residentialDetails.district}
                >
                  {data.map((dis, index) => {
                    return (
                      <Option
                        key={index}
                        value={dis.district_name}
                        onChange={handleDistrict}
                      >
                        {dis.district_name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem
                label="Taluka"
                required
                // name="taluka"
                rules={[
                  {
                    required: true,
                    message: "Please input your Taluka!",
                  },
                ]}
              >
                <SelectDropDown
                  showSearch
                  placeholder="Select taluka"
                  optionFilterProp="children"
                  onChange={(v) => {
                    setTaluka(v);
                  }}
                  // disabled={selectVisible}
                  // name="taluka"
                  value={taluka}
                  // defaultValue={taluka}
                >
                  {data[talukaKey].talukas.map((tal) => {
                    return (
                      <Option value={tal.taluka_name}>{tal.taluka_name}</Option>
                    );
                  })}
                </SelectDropDown>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Select below option" name="village" required>
                <SelectDropDown
                  placeholder="select rural/urban"
                  onChange={handleAreaTypeChange}
                >
                  <Option value="urban">urban</Option>
                  <Option value="rural">rural</Option>
                </SelectDropDown>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Pincode"
                // name="pinCode"
                required
                // rules={[
                //   { required: true, message: "Please input your Pincode" },
                // ]}
              >
                <InputFields
                  value={pinCode}
                  onChange={handlePincode}
                  placeholder="Please input your Pincode"
                  name="pinCode"
                  type="tel"
                  maxLength={6}
                  // defaultValue={residentialDetails.pinCode}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}>
              <FormItem label="Aadhar Card No" required>
                <InputFields
                  type="number"
                  maxLength="12"
                  onInput={handleAadharCardNumberChange}
                  value={aadharCardNumber}
                  name="aadharCardNumber"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Aadhar Linked Bank Name" required>
                <InputFields
                  type="text"
                  name="aadharLinkBankName"
                  value={aadharLinkBankName}
                  onChange={(e) => setAadharLinkBankName(e.target.value)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Aadhar Linked Bank Account Number" required>
                <InputFields
                  type="number"
                  name="aadharLinkBankAccountNumber"
                  value={aadharLinkBankAccountNumber}
                  onChange={(e) =>
                    setAadharLinkBankAccountNumber(e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}>
              <FormItem label="Aadhar Linked Bank IFSC Code" required>
                <InputFields
                  type="text"
                  name="aadharLinkBankifscCode"
                  value={aadharLinkBankifscCode}
                  onChange={(e) => setAadharLinkBankifscCode(e.target.value)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Income Certificate">
                <InputFields
                  type="file"
                  name="incomeCertificate"
                  onChange={incomeCertificateChange}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Ration Card">
                <InputFields
                  type="file"
                  name="rationCard"
                  onChange={rationCardChange}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}>
              <FormItem label="School/College Leaving Certificate" required>
                <InputFields
                  type="file"
                  name="schoolOrCollegeLeavingCertificate"
                  onChange={leavingCertificateChange}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Caste Certificate" required>
                <InputFields
                  type="file"
                  name="casteCertificate"
                  onChange={casteCertificateChange}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Aadhar Card" required>
                <InputFields
                  type="file"
                  name="aadharCard"
                  // value={aadharCard}
                  onChange={aadharCardChange}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "start" }}>
            <Col span={8}>
              <FormItem label="Bank Pass Book" required>
                <InputFields
                  type="file"
                  name="bankPassBook"
                  onChange={bankPassBookChange}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Passport size photo" required>
                <InputFields
                  type="file"
                  name="passportPhoto"
                  onChange={handlePassportPhotoChange}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              style={{ marginRight: "10px" }} // Adjust the spacing between checkbox and text
              checked={consentChecked}
              onChange={handleCheckboxChange}
            />
            <span>
              <h3 style={{ margin: "2px 0px" }}>Click here for the consent</h3>{" "}
            </span>
            <span>
              I,{fullName} consent to providing my Aadhaar number, bank account
              details, and other necessary information for processing my
              application for skill development training and stipend. I
              understand this data will be used solely for decision-making on my
              application and for facilitating the training program.
            </span>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginRight: "150px", width: "200px" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </MainContainer>
    </Spin>
  );
});

export default TrainingForm;
